import * as React from 'react'
import {useWizard} from 'react-use-wizard'
import NextButton from '../../components/NextButton'
import {BookingService} from "../../api/api.service";
import {toast} from "react-toastify";
import {format} from 'date-fns'

export default function Step3N({
                                   booking,
                                   setBooking,
                                   isLoadingCustom,
                                   setIsLoadingCustom,
                                   bookingId
                               }: any) {
    const {previousStep, nextStep} = useWizard()

    const handleStep2 = async () => {
        setIsLoadingCustom(true)
        booking.step = 3
        let result = await BookingService.updateBooking(booking)

        if (result.isSuccess && result.response.status === 'success') {

            let result1 = await BookingService.getBooking(
                bookingId,
                format(booking.start_date, 'yyyy-MM-dd'),
            );

            if (result1.isSuccess && result1.response.status === 'success') {
                const updatedBooking = result1.response.data;
                setBooking(updatedBooking);
            }

            nextStep()
        } else {
            toast.error('Error updating booking!')
        }

        setIsLoadingCustom(false)
    }

    return (
        <>
            <div className="form-register">
                <div className="content ">
                    <div className="inner">
                        <div>
                            <h3>Your Personal Details:</h3>
                            <div className="form-row">
                                <div className="form-holder form-holder-2">
                                    <label className="form-row-inner">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={booking.firstname + ' ' + booking.surname}
                                            disabled
                                            required
                                        />
                                        <span className="label">Lead Traveler Name </span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-holder form-holder-2">
                                    <label className="form-row-inner">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            value={booking.customer_tel_mobile}
                                            onChange={e =>
                                                setBooking({
                                                    ...booking,
                                                    customer_tel_mobile: e.target.value,
                                                })
                                            }
                                            required
                                        />
                                        <span className="label">Mobile Number</span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-holder form-holder-2">
                                    <label className="form-row-inner">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            value={booking.customer_email}
                                            onChange={e =>
                                                setBooking({
                                                    ...booking,
                                                    customer_email: e.target.value,
                                                })
                                            }
                                            required
                                        />
                                        <span className="label">Email Address</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="actions ">
                <button className="btn btn-wiz btn-prev" onClick={() => previousStep()}>
                    Previous
                </button>
                <NextButton
                    text={'Next'}
                    isLoadingCustom={isLoadingCustom}
                    handleFunction={handleStep2}
                />
            </div>
        </>
    )
}
