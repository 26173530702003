import * as React from 'react'

export default function Cancelled({ setIsFormSubmitted }) {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center ">
            <h1 className="mt-4 mb-4 color-red">Cancelled Booking</h1>
            <h2 className="font-size-1rem">
              We regret to inform you that the information you provided matches
              a booking that is currently canceled in our system. <br /> <br />
              We would recommend that you double-check with your tour agent or
              contact us at the email address{' '}
              <a href={'mailto:booking@grayline.ro'}> booking@grayline.ro</a>
            </h2>
          </div>
        </div>
      </div>
      <div className="actions justify-content-center">
        <button
          className="btn btn-wiz btn-prev"
          onClick={() => setIsFormSubmitted(false)}
        >
          Restart check-in
        </button>
        <a className="btn btn-wiz btn-a" href="https://www.grayline.ro/">
          Go to website
        </a>
      </div>
    </>
  )
}
