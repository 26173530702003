import * as React from 'react'

export default function Success({ setIsFormSubmitted }) {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="mt-4 mb-4">Success</h1>
            <h3 style={{ fontWeight: '300' }}>
              Your booking details have been confirmed. <br />
              We look forward to your visit!
            </h3>
          </div>
        </div>
      </div>
      <div className="actions justify-content-center">
        <button
          className="btn btn-wiz btn-prev"
          onClick={() => setIsFormSubmitted(false)}
        >
          Start a new check-in
        </button>
        <a
          className="btn btn-wiz btn-a"
          href="https://www.grayline.ro/tour-list/"
        >
          Find more activities
        </a>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <p>
              As a thank you for choosing us, we'd like to offer you a discount
              code for 15% off your next booking!{' '}
            </p>
            <h5>
              DISCOUNT CODE: <span style={{ color: '#013976' }}>CHKIN015</span>
            </h5>
          </div>
        </div>
      </div>
    </>
  )
}
