import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default function Footer() {
  return (
    <div className="page-footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-sm-12 col-md-12 pr-0">
            <div className="text-center text-xs-center">
              <a
                className="mr-3 d-xs-block"
                href="tel:+40 745752753"
                id="contact_footer_phone"
              >
                <FontAwesomeIcon icon={faPhone} /> +40 745752753
              </a>
              <a href="mailto:booking@grayline.ro" id="contact_footer_email">
                <FontAwesomeIcon icon={faEnvelope} /> booking@grayline.ro
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
