import * as React from 'react'
import { useState } from 'react'
import { Wizard } from 'react-use-wizard'
import Step1 from './Steps/Step1'
import HeaderForm from '../components/form/HeaderForm'
import BookingInterface from '../interfaces/Booking'
import Success from '../pages/Success'
import Cancelled from '../pages/Cancelled'
import PickupPointInterface from '../interfaces/PickupPointNew'
import Step2N from './Steps/Step2N'
import Step3N from './Steps/Step3N'
import Step4N from './Steps/Step4N'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { format } from 'date-fns'

const initialState: BookingInterface = {
  booking_id: '',
  channel_name: '',
  start_date: format(new Date(), 'yyyy-MM-dd'),
  status_text: '',
  firstname: '',
  surname: '',
  customer_email: '',
  customer_tel_mobile: '',
  customer_id: '',
  cid: '',
  lead_customer_name: '',
  lead_customer_country: '',
  lead_customer_city: '',
  lead_customer_email: '',
  lead_customer_tel_mobile: '',
  agent_name: null,
  agent_ref: {},
  compid: '',
  is_cancelled: false,
}

export default function WizzardForm() {
  const [booking, setBooking] = React.useState<BookingInterface>(initialState)
  const [bookingId, setBookingId] = useState('')
  const [pickupPoints, setPickupPoints] = useState<PickupPointInterface[]>([])
  const [selectedPickupPoint, setSelectedPickupPoint] =
    useState<PickupPointInterface | null>(null)
  const [isLoadingCustom, setIsLoadingCustom] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isBookingCancelled, setIsBookingCancelled] = useState(false)

  return (
    <div className="page-content">
      <div className="wizard-v3-content">
        <div className="wizard-form">
          <Header />
          {isFormSubmitted ? (
            isBookingCancelled ? (
              <Cancelled setIsFormSubmitted={setIsFormSubmitted} />
            ) : (
              <Success setIsFormSubmitted={setIsFormSubmitted} />
            )
          ) : (
            <Wizard startIndex={0} header={<HeaderForm />}>
              <Step1
                booking={booking}
                setBooking={setBooking}
                bookingId={bookingId}
                setBookingId={setBookingId}
                isLoadingCustom={isLoadingCustom}
                setIsLoadingCustom={setIsLoadingCustom}
                setSelectedPickupPoint={setSelectedPickupPoint}
                setPickupPoints={setPickupPoints}
                setIsFormSubmitted={setIsFormSubmitted}
                setIsBookingCancelled={setIsBookingCancelled}
              />
              <Step2N
                booking={booking}
                setBooking={setBooking}
                bookingId={bookingId}
                setBookingId={setBookingId}
                isLoadingCustom={isLoadingCustom}
                setIsLoadingCustom={setIsLoadingCustom}
                pickupPoints={pickupPoints}
                selectedPickupPoint={selectedPickupPoint}
                setSelectedPickupPoint={setSelectedPickupPoint}
              />
              <Step3N
                booking={booking}
                setBooking={setBooking}
                isLoadingCustom={isLoadingCustom}
                setIsLoadingCustom={setIsLoadingCustom}
                bookingId={bookingId}
              />
              <Step4N
                booking={booking}
                setBooking={setBooking}
                bookingId={bookingId}
                setBookingId={setBookingId}
                isLoadingCustom={isLoadingCustom}
                setIsLoadingCustom={setIsLoadingCustom}
                selectedPickupPoint={selectedPickupPoint}
                setSelectedPickupPoint={setSelectedPickupPoint}
                setPickupPoints={setPickupPoints}
                setIsFormSubmitted={setIsFormSubmitted}
              />
            </Wizard>
          )}
          <Footer />
        </div>
      </div>
    </div>
  )
}
