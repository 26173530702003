import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function NextButton({
  text,
  classesNames = 'btn btn-wiz',
  isLoadingCustom,
  handleFunction,
}: any) {
  return (
    <>
      {isLoadingCustom ? (
        <button
          className="btn btn-wiz btn-disable"
          onClick={() => handleFunction()}
        >
          <FontAwesomeIcon className="mr-2" icon={faSpinner} spin />
          {text}
        </button>
      ) : (
        <button className={classesNames} onClick={() => handleFunction()}>
          {text}
        </button>
      )}
    </>
  )
}
