import axios from 'axios'
import { toast } from 'react-toastify'

// const API_URL = 'https://turismgl.dev.verti.ro/api/public/api/'
const API_URL = 'https://check-in.grayline.ro/api/public/api/'
// const API_URL = 'http://127.0.0.1:8000/api/'

export const RequestMethod = {
  GET: 'Get',
  POST: 'Post',
  PUT: 'Put',
  PATCH: 'Patch',
  DELETE: 'Delete',
}

const getErrorMessage = error => {
  if (error?.response?.data?.error) return error.response.data.error
  else if (error?.message) return error.message
  else return error
}

export async function requestJson(path, method, options) {
  try {
    const response = await executeRequest(API_URL, path, method, options)
    return { response: response, isSuccess: true, error: null }
  } catch (ex) {
    const errorMessage = getErrorMessage(ex)

    toast.error('An error occurred. Please contact us.')
    console.error(ex?.message ?? ex)
    return { response: null, isSuccess: false, error: ex?.message ?? ex }
  }
}

async function executeRequest(baseUrl, path, method, options) {
  const url = baseUrl + path
  const headers = getHeaders(options)
  let response
  switch (method) {
    case RequestMethod.GET:
      response = await axios.get(jsonToUrl(url, options?.queryStringParams), {
        headers: headers,
      })
      break
    case RequestMethod.POST:
      response = await axios.post(
        jsonToUrl(url, options?.queryStringParams),
        options?.data,
        {
          headers: headers,
        },
      )
      break
    case RequestMethod.PUT:
      response = await axios.put(
        jsonToUrl(url, options?.queryStringParams),
        options?.data,
        {
          headers: headers,
        },
      )
      break
    case RequestMethod.PATCH:
      response = await axios.patch(
        jsonToUrl(url, options?.queryStringParams),
        options?.data,
        {
          headers: headers,
        },
      )
      break
    case RequestMethod.DELETE:
      response = await axios.delete(
        jsonToUrl(url, options?.queryStringParams),
        { headers: headers },
      )
      break
  }

  return response?.data
}

function getHeaders(options) {
  let headers = {}

  if (options?.useAccessToken) {
    const user = 'AuthService.getCurrentUser();' // user = AuthService.getCurrentUser();
    headers['Authorization'] = `Bearer ${user.token}`
  }

  if (options?.headers) {
    options.headers.forEach(header => {
      headers[header.name] = header.value
    })
  }

  return headers
}

function jsonToUrl(url, data) {
  if (data) {
    const urlData = Object.keys(data)
      .map(k => {
        if (data[k] === undefined) return
        if (Array.isArray(data[k])) {
          const result = data[k]
            .map(x => encodeURIComponent(k) + '=' + encodeURIComponent(x))
            .join('&')
          return result
        }
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
      })
      .join('&')

    if (urlData === undefined && urlData === '') return url
    return `${url}?${urlData}`
  }

  return url
}

export function getDistance(lat1, lon1, lat2, lon2) {
  const R = 6371
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c
  return distance
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}
