import * as React from 'react'
import Logo from '../assets/images/logo.svg'

export default function Header() {
  return (
    <div className="page-header">
      <a href="https://www.grayline.ro/">
        <img
          src={Logo}
          className="logo-mobile"
          alt="Gray Line Licensee logo"
          width="94"
          height="70"
        />
      </a>
      <div className="header-bar"></div>
    </div>
  )
}
