import * as React from 'react'
import { useWizard } from 'react-use-wizard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMagnifyingGlass,
  faLocationDot,
  faContactBook,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons'

export default function HeaderForm() {
  const { activeStep } = useWizard()

  return (
    <div className="wizard-header">
      <div className="wizz-title">
        <h3 className="heading">Self Check-in App</h3>
        <p>Fill all form field </p>
      </div>
      <div className="steps clearfix">
        <ul>
          <li className={`first ${activeStep >= 0 ? 'current' : ''}`}>
            <a>
              <div className="title">
                <span className="step-icon">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </span>
                <span className="step-text">Search</span>
              </div>
            </a>
          </li>
          <li className={`${activeStep >= 1 ? 'current' : ''}`}>
            <a>
              <div className="title">
                <span className="step-icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </span>
                <span className="step-text">Details</span>
              </div>
            </a>
          </li>
          <li className={`${activeStep >= 2 ? 'current' : ''}`}>
            <a>
              <div className="title">
                <span className="step-icon">
                  <FontAwesomeIcon icon={faContactBook} />
                </span>
                <span className="step-text">Contact</span>
              </div>
            </a>
          </li>
          <li className={`last ${activeStep >= 3 ? 'current' : ''}`}>
            <a>
              <div className="title">
                <span className="step-icon">
                  <FontAwesomeIcon icon={faUserCheck} />
                </span>
                <span className="step-text">Confirm</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
