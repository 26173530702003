import { requestJson, RequestMethod } from '../helpers/httpHelper'

const getBooking = async (bookingId, date) => {
  const response = await requestJson(
    'getBooking/' + bookingId + '/' + date,
    RequestMethod.GET,
  )

  return response
}

const updateBooking = async data => {
  const response = await requestJson('booking', RequestMethod.POST, {
    data,
  })

  return response
}

export const BookingService = {
  getBooking,
  updateBooking,
}
