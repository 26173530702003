import * as React from 'react'
import { useWizard } from 'react-use-wizard'
import NextButton from '../../components/NextButton'

export default function Step4N({
  booking,
  isLoadingCustom,
  selectedPickupPoint,
  setBooking,
  setBookingId,
  setPickupPoints,
  setSelectedPickupPoint,
  setIsFormSubmitted,
}: any) {
  const { previousStep } = useWizard()

  const clearState = () => {
    setBooking({})
    setBookingId('')
    setPickupPoints([])
    setSelectedPickupPoint(null)
  }

  const handleStep4 = () => {
    clearState()
    setIsFormSubmitted(true)
  }

  function formatDate(dateString: string) {
    const date = new Date(dateString)
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }

  return (
    <>
      <div className="form-register">
        <div className="content ">
          <div className="inner">
            <h3>Check all information:</h3>
            <div className="form-row mb-col">
              <div className="col-12">
                <span className="labelT">Lead Traveler Name:</span>{' '}
                {booking.firstname + ' ' + booking.surname}
              </div>
              <div className="col-12">
                <span className="labelT">Date:</span>{' '}
                {formatDate(booking.start_date)}
              </div>

              <div className="col-12">
                <span className="labelT">Mobile Number:</span>{' '}
                {booking.customer_tel_mobile}
              </div>
              <div className="col-12">
                <span className="labelT">Email Address:</span>{' '}
                {booking.customer_email}
              </div>

              <div className="col-12">
                <span className="labelT">Guided Tour:</span>{' '}
                {booking.product_note}
              </div>

              <div className="col-12">
                <span className="labelT">Pickup Time:</span>{' '}
                {booking.pickup_time}
              </div>
              <div className="col-12">
                <span className="labelT">Pickup Location:</span>{' '}
                <a
                  href={
                    'https://www.google.com/maps/place/' +
                    selectedPickupPoint?.address1
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {booking.pickup_name}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actions ">
        <button className="btn btn-wiz btn-prev" onClick={() => previousStep()}>
          Previous
        </button>
        <NextButton
          text={'Finish'}
          isLoadingCustom={isLoadingCustom}
          handleFunction={handleStep4}
        />
      </div>
    </>
  )
}
