import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useWizard } from 'react-use-wizard'
import NextButton from '../../components/NextButton'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import PickupPointInterface from '../../interfaces/PickupPointNew'
import { BookingService } from '../../api/api.service'
import { toast } from 'react-toastify'
import { getDistance } from '../../helpers/httpHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faWalking } from '@fortawesome/free-solid-svg-icons'

const defaultProps = {
  center: {
    lat: 44.430348000000016,
    lng: 26.106948999999986,
  },
  zoom: 13,
}
const containerStyle = {
  height: '450px',
}

const pickupPointsStyle = {
  width: '100%',
}

interface DistanceMatrixResult {
  mode: string
  results: string[]
}

interface Location {
  lat: number
  lng: number
}

interface SearchResult {
  name: string | undefined
  location: google.maps.LatLng | undefined
}

const googleMapsLibraries: (
  | 'places'
  | 'geometry'
  | 'drawing'
  | 'visualization'
)[] = ['places']

export default function Step2N({
  booking,
  setBooking,
  isLoadingCustom,
  setIsLoadingCustom,
  pickupPoints,
  selectedPickupPoint,
  setSelectedPickupPoint,
}: any) {
  const { previousStep, nextStep } = useWizard()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBz_X0D1dCVmbd-tQ8EwEi4WH4_LgHlllo',
    libraries: googleMapsLibraries,
  })

  const [mapCenter, setMapCenter] = useState(defaultProps.center)
  const [mapZoom, setMapZoom] = useState(defaultProps.zoom)
  const [address, setAddress] = useState('')
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(null)
  const [nearestPickupPoints, setNearestPickupPoints] = useState<
    PickupPointInterface[]
  >([])
  const [hasAddressBeenSelected, setHasAddressBeenSelected] = useState(false)
  const [selectedAddressLocation, setSelectedAddressLocation] =
    useState<Location | null>(null)
  const addressInputRef = useRef(null)
  const [isTyping, setIsTyping] = useState(false)
  const [isMapVisible, setIsMapVisible] = useState(!booking.has_pickup)

  const handleStep3 = async () => {
    setIsLoadingCustom(true)
    booking.step = 2
    booking.pickup = selectedPickupPoint

    let result = await BookingService.updateBooking(booking)

    if (result.isSuccess && result.response.status === 'success') {
      setBooking({
        ...booking,
        pickup_id: selectedPickupPoint.pickup_id,
        pickup_name: selectedPickupPoint.pickup_name,
        pickup_time: selectedPickupPoint?.time,
        has_pickup: true,
      }) // Actualizează starea booking-ului cu noul pickup_id

      nextStep()
    } else {
      toast.error('Error updating pickup point!')
    }
    setIsLoadingCustom(false)
  }

  const handleSearch = () => {
    if (!placesServiceRef.current || !address) return

    const request = {
      query: address,
      fields: ['name', 'geometry'],
      locationBias: { north: 44.5, south: 44.3, west: 26.0, east: 26.2 },
      // region: 'ro',
      // radius: '50000', // 50 km
    }

    placesServiceRef.current.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        setSearchResults(
          results.map(result => ({
            name: result.name,
            location: result.geometry?.location,
          })),
        )
      }
    })
  }

  const updateNearestPickupPoints = async selectedLocation => {
    if (!selectedLocation) return

    const distances = pickupPoints.map(point => ({
      ...point,
      distance: getDistance(
        selectedLocation.lat,
        selectedLocation.lng,
        parseFloat(point.lat),
        parseFloat(point.long),
      ),
    }))

    let nearestThree = distances
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 3)
    const origins = [{ lat: selectedLocation.lat, lng: selectedLocation.lng }]
    const destinations = nearestThree.map(point => ({
      lat: parseFloat(point.lat),
      lng: parseFloat(point.long),
    }))

    const service = new google.maps.DistanceMatrixService()

    const modes = [
      google.maps.TravelMode.WALKING,
      google.maps.TravelMode.DRIVING,
    ]
    const modeLabels = ['walkingDistance', 'drivingDistance']

    const requests: Promise<DistanceMatrixResult>[] = modes.map(
      (mode, index) =>
        new Promise<DistanceMatrixResult>(resolve => {
          service.getDistanceMatrix(
            {
              origins,
              destinations,
              travelMode: mode,
              unitSystem: google.maps.UnitSystem.METRIC,
            },
            (response, status) => {
              if (status === 'OK' && response?.rows[0]) {
                const results = response.rows[0].elements.map(element =>
                  element.duration ? element.duration.text : 'N/A',
                )
                resolve({ mode: modeLabels[index], results })
              } else {
                console.error(
                  `Distance Matrix ${mode} request failed due to `,
                  status,
                )
                resolve({
                  mode: modeLabels[index],
                  results: Array(destinations.length).fill('N/A'),
                })
              }
            },
          )
        }),
    )

    const allResults = await Promise.all(requests)
    allResults.forEach(({ mode, results }) => {
      nearestThree = nearestThree.map((point, index) => ({
        ...point,
        [mode]: results[index],
      }))
    })

    setNearestPickupPoints(nearestThree)
  }

  function formatDate(dateString: string) {
    const date = new Date(dateString)
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      handleSearch()
    })

    return () => clearTimeout(timerId)
  }, [address])

  useEffect(() => {
    pickupPoints.map((point: PickupPointInterface) => {
      if (point.pickup_id === booking.pickup_id) {
        setSelectedPickupPoint(point)
      }
    })
    setNearestPickupPoints(pickupPoints)
  }, [])

  useEffect(() => {
    if (searchResults.length > 0) {
      const selectedResult = searchResults[0]
      const location = selectedResult.location
      if (location) {
        updateNearestPickupPoints({ lat: location.lat(), lng: location.lng() })
      }
    }
  }, [searchResults])

  // @ts-ignore
  return (
    <>
      <div className="form-register">
        <div className="content ">
          <div className="inner">
            <div>
              <h3>Your Booking Details:</h3>
              <div className="form-row">
                <div className="form-holder form-holder-2">
                  <div className="col-12">
                    <span className="labelT">Start Date:</span>{' '}
                    {formatDate(booking.start_date)}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-holder form-holder-2">
                  <div className="col-12">
                    <span className="labelT">Tour Name:</span>{' '}
                    {booking.component_name}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-holder form-holder-2">
                  <div className="col-12">
                    <span className="labelT">Tour Detail:</span>{' '}
                    {booking.product_note}
                  </div>
                </div>
              </div>
              {booking.has_pickup && (
                <>
                  <div className="form-row">
                    <div className="form-holder form-holder-2">
                      <div className="col-12">
                        <span className="labelT">Pick-up time:</span>{' '}
                        {booking.pickup_time}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-holder form-holder-2">
                      <div className="col-12">
                        <span className="labelT">Pick-up location:</span>{' '}
                        {booking.pickup_name}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={isMapVisible ? 'inner mt-4' : 'inner mt-4 hidden'}>
            <h3>Pick-up address:</h3>
            <div className="form-row">
              <div className="form-holder form-holder-2">
                <label className={'form-row-inner'}>
                  <input
                    ref={addressInputRef}
                    type="text"
                    value={address}
                    onChange={e => {
                      setAddress(e.target.value)
                      if (!e.target.value) {
                        setHasAddressBeenSelected(false)
                        setNearestPickupPoints(pickupPoints)
                        setSelectedAddressLocation(null)
                        setMapZoom(13)
                      } else {
                        setIsTyping(true)
                        placesServiceRef.current =
                          new window.google.maps.places.PlacesService(
                            document.createElement('div'),
                          )
                        if (addressInputRef.current) {
                          const autocomplete =
                            new google.maps.places.Autocomplete(
                              addressInputRef.current,
                              {
                                componentRestrictions: { country: 'ro' },
                                strictBounds: true,
                                bounds: {
                                  north: defaultProps.center.lat + 0.5,
                                  south: defaultProps.center.lat - 0.5,
                                  west: defaultProps.center.lng - 0.5,
                                  east: defaultProps.center.lng + 0.5,
                                },
                              },
                            )
                          autocomplete.addListener('place_changed', () => {
                            const place = autocomplete.getPlace()
                            if (place.geometry) {
                              setAddress(place.name || '')
                              setSelectedAddressLocation({
                                lat: place?.geometry?.location?.lat() ?? 0,
                                lng: place?.geometry?.location?.lng() ?? 0,
                              })
                              setHasAddressBeenSelected(true)
                              setIsTyping(false)
                            }
                          })
                        }
                        setMapZoom(13)
                      }
                    }}
                    placeholder="Search for an address"
                  />
                </label>
                {!selectedPickupPoint && (
                  <span className="error-location">
                    Pickup from this location is not possible. Please choose one
                    of these nearby pickup locations:
                  </span>
                )}
              </div>
              <div
                className="form-holder-2 container"
                style={pickupPointsStyle}
              >
                {hasAddressBeenSelected &&
                  !isTyping &&
                  nearestPickupPoints.map((point: PickupPointInterface) => (
                    <div
                      key={point.pickup_id}
                      className="form-row-inner pickuplist"
                    >
                      <div className="row">
                        <div className="col-8">
                          <label htmlFor={`pickupPoint_${point.pickup_id}`}>
                            <input
                              type="radio"
                              id={`pickupPoint_${point.pickup_id}`}
                              name="pickupPoint"
                              value={point.pickup_id}
                              onChange={() => {
                                setSelectedPickupPoint(point)
                                setMapCenter({
                                  lat: parseFloat(point.lat),
                                  lng: parseFloat(point.long),
                                })
                                setMapZoom(13)
                              }}
                              checked={
                                selectedPickupPoint &&
                                selectedPickupPoint.pickup_id ===
                                  point.pickup_id
                              }
                            />
                            {point.pickup_name}
                          </label>
                        </div>
                        <div className="col-2">
                          <span>
                            <FontAwesomeIcon icon={faWalking} />
                            {point.walkingDistance}
                          </span>
                        </div>
                        <div className="col-2">
                          <span>
                            <FontAwesomeIcon icon={faCar} />
                            {point.drivingDistance}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="map-container">
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={mapCenter}
                  zoom={mapZoom}
                >
                  {selectedAddressLocation && (
                    <Marker
                      key={selectedAddressLocation.lat}
                      position={{
                        lat: selectedAddressLocation.lat,
                        lng: selectedAddressLocation.lng,
                      }}
                      icon="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                    />
                  )}

                  {pickupPoints.map((point: PickupPointInterface) => {
                    console.log('point1', point.pickup_id)
                    return (
                      <Marker
                        key={'id2' + point.pickup_id}
                        position={{
                          lat: parseFloat(point.lat),
                          lng: parseFloat(point.long),
                        }}
                        onClick={() => {
                          updateNearestPickupPoints({
                            lat: parseFloat(point.lat),
                            lng: parseFloat(point.long),
                          })

                          setSelectedPickupPoint(point) // Setează punctul de ridicare selectat
                          setAddress(point.pickup_name || '')
                          setSelectedAddressLocation({
                            lat: parseFloat(point.lat) ?? 0,
                            lng: parseFloat(point.long) ?? 0,
                          })
                          setHasAddressBeenSelected(true)
                        }}
                        icon={
                          selectedPickupPoint &&
                          selectedPickupPoint.pickup_id === point.pickup_id
                            ? 'http://maps.google.com/mapfiles/ms/icons/blue.png'
                            : 'http://maps.google.com/mapfiles/ms/icons/red.png'
                        }
                      />
                    )
                  })}
                </GoogleMap>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="actions">
        <button className="btn btn-wiz btn-prev" onClick={() => previousStep()}>
          Previous
        </button>

        {!isMapVisible && (
          <NextButton
            text={' Update my pick-up'}
            classesNames={'btn btn-wiz btn-upd'}
            handleFunction={() => {
              setIsMapVisible(true)
            }}
          />
        )}

        {selectedPickupPoint ? (
          <NextButton
            text={'Next'}
            isLoadingCustom={isLoadingCustom}
            handleFunction={handleStep3}
          />
        ) : (
          <NextButton
            text={'Next'}
            classesNames={'btn btn-wiz disabled'}
            isLoadingCustom={isLoadingCustom}
            handleFunction={handleStep3}
          />
        )}
      </div>
    </>
  )
}
